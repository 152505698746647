<template>
  <div>
    <el-button type="primary" size="mini" @click="showExplanation">
      薪酬说明
    </el-button>

    <el-dialog
      title="评分说明"
      :visible.sync="dialogVisible"
      width="600px"
      append-to-body
    >
      <div class="explanation-content">
        <h4>一、浮动薪酬计算方法</h4>
        <p>
          项目组成员月度浮动薪酬 = 月度审核工时 × (年浮动工资 / 2086) ×
          月度评价系数
        </p>
        <p>注：测绘外业人员分母为2294</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
    };
  },
  methods: {
    showExplanation() {
      this.dialogVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-dialog__body {
    padding: 0 20px;
}
.explanation-content {
  h4 {
    margin: 15px 0 10px;
    color: #303133;
  }

  p {
    margin: 8px 0;
    line-height: 1.6;
    color: #606266;
  }

  ul {
    margin: 8px 0;
    padding-left: 20px;

    li {
      line-height: 1.6;
      color: #606266;
    }
  }
}
</style>
